import development from './development';
import production from './production';
import trydev from './test';

const mode = process.env.NODE_ENV;

let environment = development;
// log mode to console
console.log('mode:', mode);
if (mode === 'test') environment = trydev;
if (mode === 'production') environment = production;

export default environment;
