import { Addresses } from 'types/root';
import { bkcTestnet } from 'utils/constants/chain';
import { Chain } from 'viem';

const launchpadFactoryAddresses: Addresses = {
  25925: '0xf2222529895CA04F3aF600a50b643B16D8aaCcB2'
};

const tokenFactoryAddresses: Addresses = {
  25925: '0x4FE4Ef002eCa06f85BcAF047516aed3eb0c8A138'
};

const callHelperAddresses: Addresses = {
  25925: '0x26339e699eB3b5F12342CdC7ff71A1b4A327d656'
};

const airdropFactoryAddresses: Addresses = {
  25925: '0x6D82D1c515BC40ccB53CF863f5E8bC33d3286a8F'
};

const supportChains: [Chain, ...Chain[]] = [bkcTestnet];

const environment = {
  mode: process.env.NODE_ENV,
  supportChains,
  apiUrl: 'https://asia-southeast1-snowpad-13fec.cloudfunctions.net/testnet',
  walletConnectProjectId: 'd58202e3f0854741f196484c107aef01',
  launchpadFactoryAddresses,
  tokenFactoryAddresses,
  callHelperAddresses,
  airdropFactoryAddresses,
  documentUrl: 'https://snowpad.gitbook.io/document',
  chainRpcUrl: 'https://rpc-testnet.bitkubchain.io',
  fee: 1
};

export default environment;
